// @flow
import * as React from "react";
import { Spacer, SpacerLg, SpacerSm } from "../../modules/style-guide/spacer";
 import Input from "../../modules/style-guide/input";
 import Button from "../../modules/style-guide/button";
 import Select from "../../modules/style-guide/select";
 import { Error } from "../../modules/style-guide/text";

import type { SelectItem } from "../../modules/style-guide/select";

type Props = {|
  signingUp: boolean,
  signupError: ?string,

  onSignup: (
    name: string,
    email: string,
    password: string,
    role: "INSTRUCTOR" | "STUDENT"
  ) => any
|};

type State = {|
  name: string,
  email: string,
  password: string,
  role: ?("INSTRUCTOR" | "STUDENT"),
  validationError: ?string
|};

class SignupForm extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: "",
      email: "",
      password: "",
      role: null,
      validationError: null
    };
  }

  handleSignup = () => {

    this.setState({ role: "STUDENT" });

    const { name, email, password, role } = this.state;

    if (this.props.signingUp) {
      return;
    }

    if (name.trim() === "") {
      this.setState({validationError:"Name Cannot Be Blank"});
      return;
    }
    
    if (!email.trim().toLowerCase().endsWith("@student.butte.edu")) {
        this.setState({validationError:"Must Use Butte College Student Email Address"});
        return;
    }
    
    if (password.trim() === "") {
        this.setState({validationError:"Password Cannot Be Blank"});
        return;
    }

    if (!role) {
      this.setState({ validationError: "Select your role" });
      return;
    }

    this.setState({ validationError: null });
    this.props.onSignup(name, email.trim().toLowerCase(), password, role);
  };

  handleNameChange = (name: string) => this.setState({ name });

  handleEmailChange = (email: string) => this.setState({ email });

  handlePasswordChange = (password: string) => this.setState({ password });

  render() {
    const { signingUp, signupError } = this.props;
     const { name, email, password, role, validationError } = this.state;

    return (
      <>
         <Input
           label="Name"
           placeholder="Jane Doe"
           value={name}
           onChange={this.handleNameChange}
           onEnter={this.handleSignup}
         />
         <SpacerSm />
         <Input
           label="Butte College Email"
           placeholder="jdoe001@student.butte.edu"
           value={email}
           onChange={this.handleEmailChange}
           onEnter={this.handleSignup}
         />
         <SpacerSm />
         <Input
           label="Password"
           password
           value={password}
           onChange={this.handlePasswordChange}
           onEnter={this.handleSignup}
         />
         {/*<SpacerSm />
         <Select
           label="Select your role"
           value={role || ""}
           items={[
             { id: "", label: "Select" },
             { id: "INSTRUCTOR", label: "Instructor" },
             { id: "STUDENT", label: "Student" }
           ]}
           onSelect={this.handleRoleChange}
          />*/}

         <SpacerLg />
         <Button onClick={this.handleSignup}>
           {signingUp ? "Signing up.." : "Sign up"}
         </Button>
         {validationError || signupError ? (
           <div>
             <Spacer />
             <Error>{validationError || signupError || null}</Error>
           </div>
         ) : null}
       </>
    );
  }
}

export default SignupForm;
