// @flow

const wsUrlByEnv = {
  production: "wss://development.savantes.us/api/ws",
  development: "ws://development.savantes.us/api/ws"
};

class WSConn {
  static conn: WebSocket;
  static subscribers: { [string]: ((payload: any) => any)[] };

  getConn() {
    return new Promise<WebSocket>((resolve, reject) => {
      if (WSConn.conn) {
        resolve(WSConn.conn);
        return;
      }

      WSConn.subscribers = {};
      WSConn.conn = new WebSocket(
        wsUrlByEnv[process.env.NODE_ENV || "development"]
      );
      WSConn.conn.onopen = () => {
        resolve(WSConn.conn);
      };
      WSConn.conn.onerror = () => reject();
      WSConn.conn.onmessage = this.handleMsg;

      setInterval(() => {
        WSConn.conn.send(
          JSON.stringify({
            action: "PING"
          })
        );
      }, 60000);
    });
  }

  handleMsg = ({ data }: { data: mixed }) => {
    if (typeof data !== "string") {
      return;
    }

    const json = JSON.parse(data);
    const subs = WSConn.subscribers[json.topic] || [];

    for (let i = 0; i < subs.length; i++) {
      subs[i](json.payload);
    }
  };

  async subscribe(topic: string, onMsg: (payload: any) => any) {
    const conn = await this.getConn();

    conn.send(
      JSON.stringify({
        action: "SUBSCRIBE",
        payload: topic
      })
    );

    WSConn.subscribers[topic] = [...(WSConn.subscribers[topic] || []), onMsg];
  }
}

export default WSConn;
