// @flow
import * as React from "react";
import ReactResizeDetector from "react-resize-detector";
import { Link, withRouter } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";
import { emptyAppDataCache, withAppDataCacheCtx } from "../../lib/data-cache";
import Support from "../../modules/support";
import Logo from "../../modules/style-guide/logo";
import LoadingBar from "../../modules/style-guide/loading-bar";
import {
  Spacer,
  SpacerLg,
  SpacerXLg,
  SpacerXsm
} from "../../modules/style-guide/spacer";
import { H1 } from "../../modules/style-guide/text";
import { AddIcon } from "../../modules/style-guide/icons";
import StatefulModal from "../../modules/style-guide/modal/stateful";
import NavLink from "./nav-link";
import CourseSubNav from "./course-sub-nav";
import AccountSubNav from "./account-sub-nav";
import NavTitle from "./nav-title";
import styles from "./BaseLayout.module.css";

import type { AppDataCache } from "../../lib/data-cache";
import CreateCourseForm from "../../modules/create-course-form";

type Props = {|
  cache: AppDataCache,

  children?: ?React.Node
|};

type State = {|
  contentWidth: ?number
|};

type PageContentProps = {|
  title: string,
  actions?: React.Node[],
  children: React.Node,

  width?: number
|};

const NAV_WIDTH = 210;
const PADDING = 40;
const SIDEBAR_WIDTH = 300;

export const PageContent = (props: PageContentProps) => {
  return (
    <div className={styles.content} style={{ width: props.width || "auto" }}>
      <Spacer />
      <div className={styles.contentHeader}>
        <H1>{props.title}</H1>
        <div className={styles.actionsContainer}>
          {props.actions
            ? props.actions.map((action, i) => (
                <React.Fragment key={i}>
                  <Spacer />
                  {action}
                </React.Fragment>
              ))
            : null}
        </div>
      </div>
      <SpacerLg />
      {props.children}
      <SpacerLg />
    </div>
  );
};

export const PageAsideContent = (props: any) => (
  <div className={styles.sidebar}>
    <SpacerXLg />
    {props.children}
  </div>
);

export const renderSkeleton = (progress: number, error: ?string) => (
  <BaseLayoutPage>
    <PageContent title="">
      {progress < 100 ? <LoadingBar progress={progress} /> : null}
      {error}
    </PageContent>
  </BaseLayoutPage>
);

class BaseLayoutPage extends React.Component<Props, State> {
  containerEl: ?HTMLDivElement;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      contentWidth: null
    };
  }

  static defaultProps = {
    cache: emptyAppDataCache()
  };

  get userRole() {
    const { cache } = this.props;
    if (!cache.session) {
      return "STUDENT";
    }
    return cache.profiles[cache.session.profileID].role;
  }

  handleContentResize = () => {
    if (!this.containerEl) {
      return;
    }

    const containerWidth = this.containerEl.clientWidth;
    const contentWidth =
      containerWidth - NAV_WIDTH - PADDING * 2 - SIDEBAR_WIDTH;

    this.setState({ contentWidth });
  };

  renderNewCourse() {
    if (this.userRole === "STUDENT") {
      return (
        <Link to="/courses/enroll">
          <AddIcon className={styles.addCourse} />
        </Link>
      );
    }

    return (
      <StatefulModal
        wide
        renderOpener={(isOpen, open) => (
          <AddIcon className={styles.addCourse} onClick={open} />
        )}
        renderModal={() => (
          <div className={`${styles.centered} ${styles.addCourseModal}`}>
            <CreateCourseForm />
          </div>
        )}
      />
    );
  }

  renderNav() {
    return (
      <div className={styles.nav}>
        <Link to="/">
          <Logo noText />
        </Link>

        <div className={styles.coreNav}>
          {this.userRole === "INSTRUCTOR" ? (
            <>
              <SpacerLg />
              <NavLink to="/getting-started">
                Getting Started
                <SpacerXsm horizontal />
                <span className={styles.pill}>
                  <FaDotCircle />
                </span>
              </NavLink>
            </>
          ) : null}

          {this.props.cache.session ? (
            <>
              <NavTitle>My Courses</NavTitle>
              <CourseSubNav />
            </>
          ) : null}

          {this.userRole === "INSTRUCTOR" ? (
            <>
              <NavTitle>Materials</NavTitle>
              <NavLink exact to="/questions">
                My Questions
              </NavLink>
              <NavLink to="/questions/sample">Sample Questions</NavLink>
            </>
          ) : null}
        </div>

        <AccountSubNav />
      </div>
    );
  }

  renderContent() {
    const { children } = this.props;
    const { contentWidth } = this.state;
    let content = <PageContent title="">Empty page..</PageContent>;

    React.Children.forEach(children, child => {
      if (child.type === PageContent) {
        content = React.cloneElement(child, { width: contentWidth });
      }
    });

    return content;
  }

  renderAsideContent() {
    const { children } = this.props;
    let sidebar = null;

    React.Children.forEach(children, child => {
      if (child.type === PageAsideContent) {
        sidebar = child;
      }
    });

    return sidebar;
  }

  render() {
    return (
      <ReactResizeDetector handleWidth onResize={this.handleContentResize}>
        <div className={styles.container} ref={el => (this.containerEl = el)}>
          {this.renderNav()}
          <div className={styles.main}>
            {this.renderContent()}
            {this.renderAsideContent()}
          </div>
          <Support />
        </div>
      </ReactResizeDetector>
    );
  }
}

export default withAppDataCacheCtx(withRouter(BaseLayoutPage));
