// @flow
import * as React from "react";
import { Spacer, SpacerLg } from "../../modules/style-guide/spacer";
import Input from "../../modules/style-guide/input";
import Button from "../../modules/style-guide/button";
import { Error, RunningText } from "../../modules/style-guide/text";
import styles from "./ResetPassword.module.css";

type Props = {|
  resettingPassword: boolean,
  resetPasswordError: ?string,

  onResetPassword: (email: string) => any
|};

type State = {|
  email: string
|};

class ResetPasswordForm extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      email: ""
    };
  }

  handleResetPassword = () => {
    const { resettingPassword, onResetPassword } = this.props;
    const { email } = this.state;

    if (resettingPassword) {
      return;
    }

    this.setState({ email: "" });

    onResetPassword(email);
  };

  handleEmailChange = (email: string) => this.setState({ email });

  render() {
    const { resettingPassword, resetPasswordError } = this.props;
    const { email } = this.state;

    return (
      <>
        <RunningText className={styles.explainer}>
          Enter the email you used to register on AutoGradr. We will send a
          reset password link to your email.
        </RunningText>
        <SpacerLg />
        <Input
          label="Butte College Email"
          value={email}
          onChange={this.handleEmailChange}
          onEnter={this.handleResetPassword}
        />

        <SpacerLg />
        <Button onClick={this.handleResetPassword}>
          {resettingPassword ? "Resetting Password.." : "Reset Password"}
        </Button>
        {resetPasswordError ? (
          <div>
            <Spacer />
            <Error>{resetPasswordError}</Error>
          </div>
        ) : null}
      </>
    );
  }
}

export default ResetPasswordForm;
