// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import AnonymousLayout1, {
  PageContent,
  PageFooter
} from "../anonymous-layout-1";
import { SpacerSm } from "../../modules/style-guide/spacer";
import SignupForm from "./signup-form";
import Button from "../../modules/style-guide/button";
import styles from "./Signup.module.css";

import * as AuthAPI from "../../lib/api/auth";
import * as ProfileAPI from "../../lib/api/profile";

type Props = {||};

type State = {|
  signingUp: boolean,
  signupError: ?string
|};

class SignupPage extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      signingUp: false,
      signupError: null
    };
  }

  handleSignup = async (
    name: string,
    email: string,
    password: string,
    role: "INSTRUCTOR" | "STUDENT"
  ) => {
    // $FlowFixMe: add defs for react-router
    const { history } = this.props;

    try {
      this.setState({ signingUp: true, signupError: null });

      await AuthAPI.signup(email, password);
      await ProfileAPI.updateProfile(name, null, role);

      this.setState({ signingUp: false });

      history.push("/signup/verify");
    } catch (e) {
      this.setState({
        signingUp: false,
        signupError:
          e.message === "user already exists"
            ? "An account with this email already exists"
            : "Failed to sign up"
      });
    }
  };

  render() {
    const { signingUp, signupError } = this.state;

    return (
      <AnonymousLayout1 title="Welcome to AutoGradr" subtitle="Let's get you started">
        <PageContent>
          <SignupForm
            signingUp={signingUp}
            signupError={signupError}
            onSignup={this.handleSignup}
          />
        </PageContent>

        <PageFooter>
          <div className={styles.center}>
            Already have an account?
            <SpacerSm />
            <Button el={Link} to="/login" secondary>
              Log in
            </Button>
          </div>
        </PageFooter>
      </AnonymousLayout1>
    );
  }
}

export default SignupPage;
