// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import Card from "../../modules/style-guide/card";
import { Spacer, SpacerSm } from "../../modules/style-guide/spacer";
import Input from "../../modules/style-guide/input";
import Button from "../../modules/style-guide/button";
import { Error } from "../../modules/style-guide/text";
import styles from "./Login.module.css";

type Props = {|
  loggingIn: boolean,
  loginError: ?string,

  onLogin: (email: string, password: string) => any
|};

type State = {|
  email: string,
  password: string
|};

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      email: "",
      password: ""
    };
  }

  handleLogin = () => {
    const { email, password } = this.state;

    if (this.props.loggingIn) {
      return;
    }

    this.props.onLogin(email, password);
  };

  handleEmailChange = (email: string) => this.setState({ email });

  handlePasswordChange = (password: string) => this.setState({ password });

  render() {
    const { loggingIn, loginError } = this.props;
    const { email, password } = this.state;

    return (
      <Card className={styles.loginForm}>
        <Input
          label="Butte College Email"
          value={email}
          onChange={this.handleEmailChange}
          onEnter={this.handleLogin}
        />
        <SpacerSm />
        <Input
          label="Password"
          password
          value={password}
          onChange={this.handlePasswordChange}
          onEnter={this.handleLogin}
        />
        <SpacerSm />
        <div className={styles.forgotPassword}>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>

        <Spacer />
        <Button onClick={this.handleLogin}>
          {loggingIn ? "Logging in.." : "Log In"}
        </Button>
        {loginError ? (
          <div>
            <Spacer />
            <Error>{loginError}</Error>
          </div>
        ) : null}
      </Card>
    );
  }
}

export default LoginForm;
